import '../css/app.css';
import.meta.glob(['../fonts/**']);

import './bootstrap';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from 'ziggy-js';
import { i18nVue } from 'laravel-vue-i18n';

import store from '@/store';
import { flareVue } from '@flareapp/flare-vue';
import { flare } from '@flareapp/flare-client';
import eventsPlugin from './eventsPlugin';

// only launch in production, we don't want to waste your quota while you're developing.
if (process.env.NODE_ENV === 'production') {
    flare.light('51PZ7bpC62uCEI1XMBr9RpAe8XnDHydx');
}

import { inject } from 'vue';

createInertiaApp({
    progress: {
        color: '#29d',
        includeCSS: false,
    },
    resolve: (name) =>
        resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .mixin({
                inject: ['$fire', '$when'],
                mounted() {
                    if (Object.keys(this.$page.props?.auth?.user ?? {}).length > 0) {
                        flare.addContextGroup('user', {
                            id: this.$page.props.auth.user.id,
                        });
                    }
                },
                methods: {
                    route,
                    can: function (permission) {
                        return this.$page.props.auth.user.permissions.includes(permission);
                    },
                },
            })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(i18nVue, {
                resolve: (lang) => {
                    const langs = import.meta.globEager('../../lang/*.json');
                    return langs[`../../lang/${lang}.json`].default;
                },
            })
            .use(store)
            .use(eventsPlugin)
            .use(flareVue)
            .mount(el);
    },
});
