const state = {
    pageTitle: 'Dashboard',
    visitHistory: JSON.parse(sessionStorage.getItem('visitHistory')) || [],
};

// getters these are computed variant for store objects
const getters = {
    getPageTitle: (state, getters, rootState) => {
        return state.pagetTitle;
    },
    getVisitHistory: (state, getters, rootState) => {
        return state.visitHistory;
    },
};

// actions
const actions = {
    setPageTitle({ commit, state }, pageTitle) {
        commit('setPageTitle', pageTitle);
    },
    addPathToVisitHistory({ commit, state }, path) {
        commit('addPathToVisitHistory', path);
    },
    removePreviousVisitHistoryItem({ commit, state }) {
        commit('removePreviousVisitHistoryItem');
    },
};

// mutations
const mutations = {
    setPageTitle(state, pageTitle) {
        state.pageTitle = pageTitle;
    },
    addPathToVisitHistory(state, path) {
        if (path != state.visitHistory[0]) {
            state.visitHistory.unshift(path);
        }

        if (path == state.visitHistory[2]) {
            let original_history = state.visitHistory;

            let removeFromHistory = [original_history[0], original_history[1]];

            let history = original_history.filter((x) => !removeFromHistory.includes(x));

            state.visitHistory = history;

            state.visitHistory.unshift(path);
        }

        // if path is dahboard, reset history
        if (path == '/') {
            state.visitHistory = ['/'];
        }

        sessionStorage.setItem('visitHistory', JSON.stringify(state.visitHistory));
    },
    removePreviousVisitHistoryItem(state) {
        state.visitHistory.splice(0, 1);
        sessionStorage.setItem('visitHistory', JSON.stringify(state.visitHistory));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
