// event bus to allow easier communications between components
// do not abuse, because this because an anti-pattern really quickly
import mitt from 'mitt';

const bus = mitt();

// import {onBeforeUnmount} from "vue";

export default {
    install: (app, options) => {
        const fire = async function (event, args) {
            bus.emit(event, args);
        };

        const when = async function (event, callback) {
            bus.on(event, callback);
        };

        app.provide('$fire', fire);
        app.provide('$when', when);

        // we should be able to unregister an event, but not sure how to listen for component
        // lifecycle hooks in vuejs3
        // shouldn't be to much of an issue, since we don't use a vuejs router
        // onBeforeUnmount(() => {
        //     // make sure to automatically remove the listener when the component gets destroyed
        //     bus.off(event, callback);
        // })
    },
};
